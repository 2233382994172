import React, { useEffect, useState } from 'react';
import { Button, DropdownProps, Modal } from 'semantic-ui-react';
import * as FileSaver from 'file-saver';

import ReactDropzone from 'react-dropzone';
import styled from 'src/theme';
import { useImportAccountsMutation } from 'src/services';
import { toastSuccess, toastError } from 'src/components/shared';
import { useAllPackages } from 'src/hooks/usePackages';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';
import { Dropdown } from '@120wateraudit/envirio-components';
import { transformSoftwarePackagesToDropdownOptions } from 'src/components/AccountSoftwarePackages/UpdatePackage';
import { useAllFeatures } from 'src/hooks/useFeatures';
import { getFeaturesDropdown } from 'src/components/AccountSoftwarePackages/AddFeature';
import { APIProvider, AWS_BASE } from 'src/utils/API';
import { FlatButton } from '@120wateraudit/waterworks';
import { AccountImportJobs } from './AccountImportJobs';
import { useToggle } from 'src/hooks';

interface Props {
  open: boolean;
  onClose(): void;
}

export const ImportAccountsModal = ({ onClose, open }: Props): JSX.Element => {
  const { packages: allPackages } = useAllPackages(AccountType.PWS);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [importAccounts] = useImportAccountsMutation();
  const { allFeatures } = useAllFeatures(AccountType.PWS);
  const [selectedFeatures, setSelectedFeatures] = useState<number[]>([]);
  const { enabled: imported, toggle: toggleImported } = useToggle();

  const [selectedPackage, setSelectedPackage] = useState<number | null>(null);

  const onSelectedSoftwarePackageChanged = (
    event: React.SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (typeof value === 'number') {
      setSelectedPackage(value);
    } else {
      setSelectedPackage(null);
    }
  };

  useEffect(() => {
    if (imported) {
      onImport();
    }
  }, [imported]);

  const onFileDropped = ([droppedFile]: File[]) => {
    setFile(droppedFile);
  };

  const onImport = async () => {
    if (file) {
      await importAccounts({
        file,
        packageId: selectedPackage,
        featureIds: selectedFeatures
      })
        .unwrap()
        .then(() => toastSuccess('Successfully started account import.'))
        .catch(() =>
          toastError(
            'There was an error importing your accounts, please try again.'
          )
        );
    }
  };

  const onResetForm = () => {
    setFile(undefined);
    setSelectedPackage(null);
    setSelectedFeatures([]);
    toggleImported();
  };

  const onSelectFeature = (e: any, d: any) => {
    setSelectedFeatures(d.value);
  };

  const closeModal = () => {
    setFile(undefined);
    onClose();
  };

  const onDownloadTemplate = async () => {
    try {
      const results: any = await APIProvider.fetchCSV(
        `${AWS_BASE}/platform/account-management/rest/import/accounts/template`
      );

      const blob = new Blob([results], {
        type: 'text/csv'
      });

      FileSaver.saveAs(blob, `AccountsImportTemplate.csv`, true);
    } catch (e) {
      /* */
    }
  };

  return (
    <Modal closeIcon onClose={closeModal} open={open} size="large">
      <Modal.Header>Import Accounts</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h6 style={{ margin: 0 }}>
            You can create or update PWS Accounts using the form below. Only NEW
            accounts will have the selected Package/Features updated.
          </h6>
        </Modal.Description>
        <Modal.Description
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10
          }}>
          <p>
            NOTE: PWS_ID should be something like "IN1234567". Matching is done
            exclusively on PWS_ID and we assume a single account per PWS_ID.
          </p>
          <FlatButton onClick={onDownloadTemplate}>
            Download Template
          </FlatButton>
        </Modal.Description>
        {!imported && (
          <>
            <ModalDropdown
              label="Software Package"
              placeholder={'No Package Selected'}
              options={transformSoftwarePackagesToDropdownOptions(allPackages)}
              value={selectedPackage}
              onChange={onSelectedSoftwarePackageChanged}
              fluid
              optionTextMaxLength={45}
              search
            />
            <ModalDropdown
              multiple
              label="Individual Features"
              placeholder={'No Features Selected'}
              options={getFeaturesDropdown(allFeatures)}
              value={selectedFeatures}
              onChange={onSelectFeature}
            />
            <ReactDropzone
              onDrop={onFileDropped}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}>
              <UploadInstructions>
                <p>
                  {file?.name
                    ? file.name
                    : `Drag and Drop a file, or click to Browse.`}
                </p>
              </UploadInstructions>
            </ReactDropzone>

            <Modal.Actions style={{ marginTop: 15 }}>
              <Button onClick={closeModal}>Cancel</Button>
              <Button disabled={!file} onClick={toggleImported} positive>
                {'Import'}
              </Button>
            </Modal.Actions>
          </>
        )}
        {imported && (
          <Modal.Description
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 10
            }}>
            <hr />
            <h3>You have successfully launched an accounts import!</h3>
            <p>
              The import job will appear automatically below, please do not
              immediately reupload the same file or duplicated accounts may be
              created.
            </p>
            <FlatButton onClick={onResetForm}>Reset Import Form</FlatButton>
          </Modal.Description>
        )}
      </Modal.Content>
      <Modal.Description>
        <AccountImportJobs />
      </Modal.Description>
    </Modal>
  );
};

const UploadInstructions = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
  align-items: center;
  width: 75%;
  text-align: center;
  height: 116px;
  border-radius: 4px;
  border: 2px dashed ${props => props.theme.colors.primary};
  margin-top: 2rem;

  p {
    color: ${props => props.theme.colors.black50};
  }
`;

const ModalDropdown = styled(Dropdown)`
  margin-top: 1rem;
  > .dropdown {
    width: 100%;
    > .icon {
      float: right;
    }
  }
`;
