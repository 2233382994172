import { SearchParams, Filters, Sort, SortDirection } from 'src/services/types';

export const convertSortToString = (sort: Sort): string => {
  return sort.direction === SortDirection.DESC
    ? `${sort.key + 'DESC'}`
    : `${sort.key + 'ASC'}`;
};

export const addFilters = (
  params: URLSearchParams,
  activeFilters: Filters
): URLSearchParams => {
  const filterEntries = Object.entries(activeFilters);
  filterEntries.forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      params.set(key, value.toString());
    }
  });
  return params;
};

export const buildParameters = ({
  filters,
  pageNumber,
  pageSize,
  searchTerm,
  excludeFromUser,
  accountId,
  excludeFromAccount,
  sort,
  searchQueryType = 'filter'
}: Partial<SearchParams>): URLSearchParams => {
  const params = new URLSearchParams();
  if (pageNumber) {
    params.set('pageNumber', (pageNumber - 1).toString());
  }
  if (pageSize) {
    params.set('pageSize', pageSize.toString());
  }
  if (searchTerm) {
    params.set(searchQueryType, searchTerm);
  }
  if (sort && typeof sort === 'object') {
    params.set('sort', convertSortToString(sort));
  } else if (sort) {
    params.set('sort', sort);
  }
  if (filters) {
    addFilters(params, filters);
  }
  if (excludeFromUser) {
    params.set('excludeFromUser', excludeFromUser.toString());
  }
  if (accountId) {
    params.set('accountId', accountId.toString());
  }
  if (excludeFromAccount) {
    params.set('excludeFromAccount', excludeFromAccount.toString());
  }
  return params;
};
