import {
  colors,
  fontSize,
  lineHeight,
  spacing
} from '@120wateraudit/envirio-components'
import * as React from 'react'
import { Breadcrumb, Button, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

// Types
import {
  School,
  SchoolDistrict,
  User
} from '@120wateraudit/envirio-components/dist/models'

// Components
import InviteForm from 'src/components/Forms/UserInvite'
import { RoleOptionToggle } from 'src/containers/AccountUsers/invite'
import { Auth0Role } from '../../types/Auth0Role'

interface Props {
  user: Partial<User> & {
    applicationRoles: { [key in Auth0Role['_id']]: boolean }
    schools: School[]
    schoolDistricts: SchoolDistrict[]
    roleOptionToggle: RoleOptionToggle
    useSSO: boolean
  }
  onCheckboxChanged: any
  ssoCheckboxChanged: () => void
  onInputChanged: any
  onSelectListChanged: any
  isSaving: boolean
  error: any
  onClickInvite: any
  onCancelClicked: any
  roleOptions: any
  schoolDistrictOptions: any
  schoolOptions: any
  onRoleTypeChanged: any
  onSchoolDistrictsChanged: any
  onSchoolsChanged: any
  applicationRoles: Auth0Role[]
}

const InviteUser = ({
  user,
  onInputChanged,
  isSaving,
  error,
  onClickInvite,
  onCheckboxChanged,
  ssoCheckboxChanged,
  onCancelClicked,
  onSelectListChanged,
  roleOptions,
  schoolDistrictOptions,
  schoolOptions,
  onRoleTypeChanged,
  onSchoolDistrictsChanged,
  onSchoolsChanged,
  applicationRoles
}: Props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      marginTop: '20px'
    }}>
    <Segment clearing attached>
      <Breadcrumb size="huge">
        <Breadcrumb.Section link onClick={onCancelClicked}>
          Account
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Invite User</Breadcrumb.Section>
      </Breadcrumb>
    </Segment>
    <Segment attached clearing loading={isSaving}>
      <InviteForm
        user={user}
        onInputChanged={onInputChanged}
        onCheckboxChanged={onCheckboxChanged}
        ssoCheckboxChanged={ssoCheckboxChanged}
        onSelectListChanged={onSelectListChanged}
        roleOptions={roleOptions}
        schoolDistrictOptions={schoolDistrictOptions}
        schoolOptions={schoolOptions}
        onRoleTypeChanged={onRoleTypeChanged}
        onSchoolDistrictsChanged={onSchoolDistrictsChanged}
        onSchoolsChanged={onSchoolsChanged}
        applicationRoles={applicationRoles}
      />
      {error && <FormGroupError>{error}</FormGroupError>}
    </Segment>
    {
      <Segment attached clearing>
        <Button secondary floated="left" onClick={onCancelClicked}>
          Cancel
        </Button>
        <Button
          primary
          floated="right"
          disabled={isSaving}
          loading={isSaving}
          onClick={onClickInvite}>
          {isSaving ? 'Sending invite...' : 'Invite'}
        </Button>
      </Segment>
    }
  </div>
)

export default InviteUser

export const FormGroupError = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  color: ${colors.error};
  padding-top: ${spacing.mini};
  font-size: ${fontSize.caption};
  line-height: ${lineHeight.caption};
`
