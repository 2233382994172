import { Button, Datepicker, Modal } from '@120wateraudit/envirio-components';
import * as React from 'react';
import { Dropdown, DropdownItemProps, Input, Table } from 'semantic-ui-react';
import 'src/components/Forms/AccountSettings/styles.css';
import Grid from 'src/components/Grid';

// Models
import {
  Account,
  Setting
} from '@120wateraudit/envirio-components/dist/models';
import { AWS_BASE } from 'src/utils/API';
import { stateOptions } from 'src/utils/SelectList';

interface Props {
  account: Account;
  gridRef: any;
  isCreating: boolean;
  isEditing: boolean;
  isModalOpen: boolean;
  newSetting?: any;
  onInputChanged: any;
  settings: Setting[];
  targetSetting?: Setting;
  templateOptions?: DropdownItemProps[];
  timezoneOptions?: DropdownItemProps[];
  onClickDelete: () => any;
  onClickEdit: (e: Setting) => any;
  onClickUpdate: () => any;
  onSettingValueChanged: (e: any) => any;
  onTemplateChanged?: (e: any, data: any) => any;
  onDropdownChanged?: (e: any, data: any) => any;
  onLeadBanDateChanged?: (date: Date) => any;
  toggleEditing: () => any;
  toggleModal: () => any;
}

const SettingsForm = ({
  account,
  gridRef,
  isEditing,
  isModalOpen,
  newSetting,
  onClickDelete,
  onClickEdit,
  onClickUpdate,
  onSettingValueChanged,
  onTemplateChanged,
  onDropdownChanged,
  onLeadBanDateChanged,
  targetSetting,
  templateOptions,
  timezoneOptions,
  toggleEditing,
  toggleModal
}: Props) => (
  <div style={{ marginTop: '20px' }}>
    <Grid
      columns={['Setting', 'Value', '']}
      fetchUrl={`${AWS_BASE}/platform/account-management/rest/accounts/${account.id}/accountsettings`}
      pageSize={5}
      ref={gridRef}
      rowRenderer={(setting: Setting) => (
        <Table.Row key={`setting-${setting.id}`}>
          <Table.Cell singleLine>{setting.settingDefinition.name}</Table.Cell>
          {isEditing && targetSetting && targetSetting.id === setting.id
            ? [
                <Table.Cell key={`new-${setting.value}`}>
                  {setting.settingDefinition.name === 'SendGrid Email Template'
                    ? [
                        <Dropdown
                          key="email-template-dropdown"
                          placeholder="Select an Email Template"
                          fluid
                          selection
                          options={templateOptions}
                          onChange={onTemplateChanged}
                          value={newSetting || targetSetting.value}
                        />
                      ]
                    : setting.settingDefinition.name === 'Timezone'
                    ? [
                        <Dropdown
                          key="timezone-template-dropdown"
                          placeholder="Select a Timezone"
                          fluid
                          selection
                          options={timezoneOptions}
                          onChange={onDropdownChanged}
                          value={newSetting || targetSetting.value}
                        />
                      ]
                    : setting.settingDefinition.name === 'Lead Ban Date'
                    ? [
                        <Datepicker
                          name="value"
                          key="lead-ban-dropdown"
                          onChange={onLeadBanDateChanged}
                          value={new Date(targetSetting.value)}
                        />
                      ]
                    : setting.settingDefinition.name === 'ReturnAddressState'
                    ? [
                        <Dropdown
                          key="return-address-state-dropdown"
                          placeholder="Select a State"
                          fluid
                          selection
                          options={stateOptions}
                          onChange={onDropdownChanged}
                          value={newSetting || targetSetting.value}
                        />
                      ]
                    : [
                        <Input
                          key="input-text"
                          fluid
                          type="text"
                          value={targetSetting.value}
                          onChange={onSettingValueChanged}
                        />
                      ]}
                </Table.Cell>,
                <Table.Cell
                  singleLine
                  width="three"
                  key={`edit-buttons-setting-${setting.value}`}>
                  <Button variant="primary" onClick={onClickUpdate}>
                    Update
                  </Button>
                  <Button onClick={toggleEditing}>Cancel</Button>
                  <Button variant="error" onClick={toggleModal}>
                    X
                  </Button>
                  {isModalOpen && (
                    <Modal
                      isOpen
                      toggle={toggleModal}
                      content={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                          }}>
                          <h3 style={{ textAlign: 'center' }}>
                            Delete {targetSetting.settingDefinition.name}
                          </h3>
                          <div style={{ margin: '20px 0' }}>
                            <p
                              style={{
                                fontSize: '1.33rem',
                                textAlign: 'center',
                                fontWeight: 'bold'
                              }}>
                              Are you sure you want to delete this setting?
                            </p>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}>
                            <Button onClick={toggleModal}>Cancel</Button>
                            <Button variant="success" onClick={onClickDelete}>
                              Confirm
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  )}
                </Table.Cell>
              ]
            : [
                <Table.Cell key={`setting-${setting.value}`}>
                  {setting.value}
                </Table.Cell>,
                <Table.Cell singleLine width={1} key="edit-setting">
                  <Button
                    variant="primary"
                    onClick={() => {
                      onClickEdit(setting);
                    }}>
                    Edit
                  </Button>
                </Table.Cell>
              ]}
        </Table.Row>
      )}
    />
  </div>
);

export default SettingsForm;
