import React, { FC } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { Container } from 'semantic-ui-react'
import { Configuration } from 'src/types/Configuration'
import { orderBy } from 'lodash'
import styled from 'styled-components'
import {
  createOrUpdateLabConfiguration,
  useAnalytes,
  useEDDConfigs,
  useUnitsOfMeasure
} from './dataAccess'
import { EddConfigurationForm } from './EddConfigurationForm'
export interface EddConfigurationValues {
  analytes: AnalyteConfigurationValues[]
}
interface AnalyteConfigurationValues {
  analyteId: number
  labAnalyteId?: string
  unitId?: number
  labUnitId?: string
}
interface LocationState {
  labName?: string
}

export const LabConfigurations: FC = () => {
  const { id: labId } = useParams<{
    id: number
  }>()
  const location = useLocation()
  const labName = (location.state as LocationState)?.labName

  const { units, loading: unitsLoading } = useUnitsOfMeasure()
  const { analytes, loading: analytesLoading } = useAnalytes()
  const {
    labConfig,
    loading: labConfigLoading,
    refetch: refetchLabConfig
  } = useEDDConfigs(labId)

  const onSaveClicked = async (values: EddConfigurationValues) => {
    const configurations = values.analytes.map(analyte => {
      const targetUnit = units.find(unit => {
        return unit.id === analyte.unitId
      })
      return {
        analyte: {
          lab: analyte.labAnalyteId,
          id: analyte.analyteId
        },
        unit: {
          lab: analyte.labUnitId,
          id: targetUnit?.enumValue,
          conversion: null
        }
      }
    })

    const errors = validateConfigurations(configurations)

    if (errors.length) {
      return toastError(errors.join(' '))
    }

    await createOrUpdateLabConfiguration({
      id: labConfig?.id,
      labId,
      configurations
    })

    const successMessage = `Successfully updated Configuration for Lab ${labName}`
    toastSuccess(successMessage)

    refetchLabConfig()
  }

  const validateConfigurations = (filteredMappings: Configuration[]) => {
    const errorMessages: string[] = []
    const incompleteMappings = filteredMappings.filter(mapping => {
      return (
        !mapping.analyte?.lab ||
        !mapping.unit.lab ||
        mapping.unit.id === undefined
      )
    })

    if (incompleteMappings.length) {
      errorMessages.push(
        'Incomplete mappings found. Mappings must have values in all fields to be valid.'
      )
    }
    const analyteValues = filteredMappings.map(mapping => {
      return mapping.analyte?.lab?.toLowerCase()
    })
    const duplicateAnalyteValues = analyteValues.filter(
      (value, index) => analyteValues.indexOf(value) !== index
    )

    if (duplicateAnalyteValues.length) {
      errorMessages.push(
        'Duplicate analyte values found. Analyte values for each mapping must be unique.'
      )
    }

    return errorMessages
  }

  const toastSuccess = (message: string) => {
    toast(message, {
      position: 'top-center',
      hideProgressBar: true,
      type: 'success'
    })
  }

  const toastError = (message: string) => {
    toast(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: true,
      type: 'error'
    })
  }

  const generateInitialValues = (): EddConfigurationValues => {
    const filteredAnalytes = orderBy(analytes, 'displayName', 'asc').filter(
      analyte => {
        return labConfig?.configurations?.some(config => {
          return config.analyte?.id === analyte.id
        })
      }
    )
    const analyteMap = filteredAnalytes?.map(analyte => {
      const targetConfig = labConfig?.configurations.find(config => {
        return config.analyte?.id === analyte.id
      })
      const targetUnit = units.find(unit => {
        return targetConfig?.unit.id === unit.enumValue
      })
      return {
        analyteId: analyte.id,
        labAnalyteId: targetConfig?.analyte?.lab,
        unitId: targetUnit?.id,
        labUnitId: targetConfig?.unit.lab
      }
    })
    return {
      analytes: analyteMap
    }
  }
  const labAnalyteDecorator = createDecorator({
    field: /analytes\[\d+\]\analyteId/,
    updates: (value, name, allValues) => {
      const index = name.replace(/analytes\[|\]analyteId/g, '')
      const valueName = 'analytes'
      const existingValue = allValues?.[valueName][index].labAnalyteId
      const fieldName = name.replace('analyteId', 'labAnalyteId')
      const newValue = analytes.find(analyte => analyte.id === value)
      return {
        [fieldName]: existingValue || newValue?.displayName
      }
    }
  })

  const labUnitDecorator = createDecorator({
    field: /analytes\[\d+\]\unitId/,
    updates: (value, name, allValues) => {
      const index = name.replace(/analytes\[|\]unitId/g, '')
      const valueName = 'analytes'
      const existingValue = allValues?.[valueName][index].labUnitId
      const fieldName = name.replace('unitId', 'labUnitId')
      const newValue = units.find(unit => unit.id === value)
      return {
        [fieldName]: existingValue || newValue?.unitOfMeasure
      }
    }
  })

  return (
    <>
      {!analytesLoading && !unitsLoading && !labConfigLoading && (
        <Container>
          <Heading>EDD Configuration for {labName}</Heading>
          <Form
            onSubmit={onSaveClicked}
            mutators={{ ...arrayMutators }}
            initialValues={generateInitialValues()}
            initialValuesEqual={() => true}
            decorators={[labAnalyteDecorator, labUnitDecorator]}
            render={({ handleSubmit, values }) => (
              <EddConfigurationForm
                analytes={analytes}
                units={units}
                onSubmit={handleSubmit}
                values={values}
              />
            )}
          />
        </Container>
      )}
    </>
  )
}

const Heading = styled.h4`
  padding-top: 2rem;
`
