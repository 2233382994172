import * as React from 'react';
import { SyntheticEvent, useState } from 'react';

// Components
import { Search } from 'semantic-ui-react';
import { Button } from '@120wateraudit/waterworks';
import { Modal } from '@120wateraudit/envirio-components';
import styled from 'styled-components';
import { SearchResult, toastError, toastSuccess } from 'src/components/shared';

// Custom Hooks
import {
  useGetUserAccountsQuery,
  useAddAccountToUserMutation
} from 'src/services';

interface Props {
  user: any;
  toggleAddingToAccount: () => void;
  addingToAccount: boolean;
}

const AddAccountModal = ({
  user,
  toggleAddingToAccount,
  addingToAccount
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
  const { data: responseAccounts } = useGetUserAccountsQuery({
    userId: user.id,
    paramsData: {
      pageNumber: 1,
      pageSize: 10,
      searchTerm,
      excludeFromUser: true
    }
  });
  const [addAccount] = useAddAccountToUserMutation();
  const [accountId, setAccountId] = useState<number | undefined>(undefined);
  const onSubmitAddAccount = async () => {
    if (accountId && user) {
      await addAccount({
        userId: user.id,
        accountId
      })
        .unwrap()
        .then(() => toastSuccess('Successfully added account to this user.'))
        .catch(() => toastError('Add account failed, please try again.'));
      toggleAddingToAccount();
      setSearchTerm(undefined);
      setAccountId(undefined);
    }
  };

  const onSelectAccount = async (
    event: SyntheticEvent,
    data: { result: { id: number; title: string } }
  ) => {
    setSearchTerm(data.result.title);
    setAccountId(data.result.id);
  };

  const accounts = responseAccounts?.items || [];
  const accountsRecord = accounts.map(account => ({
    id: account.id,
    title: account.name,
    description: account.id.toString()
  }));

  return (
    <Modal
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <h4>Add Account To User</h4>
          <SearchWrapper>
            <Search
              input={{ icon: 'search', iconPosition: 'left' }}
              fluid={true}
              noResultsMessage="No Accounts Found"
              placeholder="Search for an account"
              onBlur={() => {
                if (!searchTerm) {
                  // If we end up without a search term we assume the user is clearing the search
                  setSearchTerm(undefined);
                  setAccountId(undefined);
                }
              }}
              onFocus={(e: SyntheticEvent) =>
                (e.target as HTMLInputElement).select()
              }
              onResultSelect={onSelectAccount}
              onSearchChange={(_, data) => setSearchTerm(data.value)}
              resultRenderer={SearchResult}
              results={accountsRecord}
              value={searchTerm}
            />
          </SearchWrapper>
          <div>
            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'center'
              }}>
              <Button
                onClick={() => {
                  setSearchTerm('');
                  toggleAddingToAccount();
                }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => onSubmitAddAccount()}>
                Add
              </Button>
            </div>
          </div>
        </div>
      }
      isOpen={addingToAccount}
      overflowStyle="visible"
      toggle={toggleAddingToAccount}
    />
  );
};

const SearchWrapper = styled.div`
  width: 80%;
  &&& {
    .ui.input {
      width: 100%;
    }
  }
`;

export default AddAccountModal;
