import { Button, ConfirmModal } from '@120wateraudit/envirio-components';
import gql from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';
import { Form as FinalForm } from 'react-final-form';
import { ToastOptions, toast } from 'react-toastify';
import { Form, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import { ACCOUNT_QUERY } from 'src/containers/Accounts/data-access';
import {
  AccountForm,
  AccountWithTimezone,
  sanitizeAccount,
  validate
} from 'src/modules/Account/Form';

const toastSuccess = (message: string) =>
  toast(message, {
    ...TOAST_OPTIONS,
    type: 'success'
  });

interface Props {
  account: AccountWithTimezone;
}

interface State {
  subdomainConfirmation: boolean;
  subdomainToSetTo: string | undefined;
}

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount($account: UpdateAccountInputType) {
    updateAccount(account: $account) {
      id
    }
  }
`;

class AccountDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      subdomainConfirmation: false,
      subdomainToSetTo: props.account.subdomain
    };
  }

  showConfirmationModal = (
    shouldShow: boolean,
    subdomain: string | undefined
  ): void => {
    this.setState({
      subdomainConfirmation: shouldShow,
      subdomainToSetTo: subdomain
    });
  };

  onSubmit = async (mutation, values) => {
    // If the account's subdomain was changed and we're not showing the confirmation we should show it
    if (
      values.subdomain !== this.props.account.subdomain &&
      !this.state.subdomainConfirmation
    ) {
      return this.showConfirmationModal(true, values.subdomain);
    }

    // Otherwise we should update the account
    const { ...account } = sanitizeAccount(values);
    await mutation({
      variables: { account: { ...account, id: this.props.account.id } }
    });
    this.setState({
      subdomainConfirmation: false
    });
    toastSuccess('Successfully updated Account information');
  };

  render() {
    const { account: acct } = this.props;
    return (
      <Mutation
        mutation={UPDATE_ACCOUNT_MUTATION}
        refetchQueries={() => {
          return [
            {
              query: ACCOUNT_QUERY,
              variables: { id: acct.id }
            }
          ];
        }}>
        {(updateAccount, { loading }) => (
          <FinalForm
            initialValues={acct}
            onSubmit={values => this.onSubmit(updateAccount, values)}
            render={({ handleSubmit, valid, values, form: { change } }) => (
              <Container>
                <TitleRow attached>
                  <h4>{acct.name}</h4>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1',
                      justifyContent: 'flex-end'
                    }}>
                    <Form.Checkbox
                      checked={!values.isAccountDisabled}
                      label="Is Active"
                      onChange={() =>
                        change('isAccountDisabled', !values.isAccountDisabled)
                      }
                    />
                    <div style={{ marginLeft: '1rem' }}>
                      <Form.Checkbox
                        checked={values.isDemo}
                        label="Is Demo Account"
                        onChange={() => change('isDemo', !values.isDemo)}
                      />
                    </div>
                  </div>
                </TitleRow>
                <Segment attached>
                  <AccountForm />
                </Segment>
                <Segment attached clearing>
                  <Button
                    disabled={loading || !valid}
                    onClick={handleSubmit}
                    variant="primary">
                    {loading ? 'Updating...' : 'Update'}
                  </Button>
                </Segment>
                <ConfirmModal
                  header="Are you sure?"
                  isOpen={this.state.subdomainConfirmation}
                  message={`Please confirm you'd like to set the Account subdomain to: ${this.state.subdomainToSetTo}`}
                  onConfirm={handleSubmit}
                  toggle={() => this.showConfirmationModal(false, '')}
                />
              </Container>
            )}
            validate={validate}
          />
        )}
      </Mutation>
    );
  }
}

const TOAST_OPTIONS: ToastOptions = {
  autoClose: 2500,
  hideProgressBar: true,
  position: 'top-center'
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TitleRow = styled(Segment)`
  background-color: blue;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin: 0;
  }
`;

export default AccountDetails;
