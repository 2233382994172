import React, { useState } from 'react';

// Models
import { Account, Role, User } from 'src/types/AccountManagementTypes';
import { AccountType } from '@120wateraudit/envirio-components/dist/models';

// Components
import styled from 'src/theme';
import { Card, Table as WWTable, Button } from '@120wateraudit/waterworks';
import RoleActionWrapper from 'src/components/Users/RoleActionWrapper';
import RemoveAccountModal from 'src/components/Users/RemoveAccountModal';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetUserAccountsQuery } from 'src/services';

interface TableProps {
  user: User;
  roles: Role[];
  fetchUser: () => void;
}

const AccountsTable = ({ user, roles, fetchUser }: TableProps) => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber
  } = useTableState({});
  const { data: response, isFetching } = useGetUserAccountsQuery({
    userId: user.id,
    paramsData: params
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isRemovingUserOpen, setRemoveUserOpen] = useState(false);
  const [account, setAccount] = useState<Account>();

  const COLUMNS = [
    {
      key: 'id',
      Header: 'Account Id',
      accessor: 'id'
    },
    {
      key: 'name',
      Header: 'Account Name',
      accessor: ({ name }: Account) => (
        <AccountNameWrapper>{name}</AccountNameWrapper>
      )
    },
    {
      key: 'accountType',
      Header: 'Account Type',
      accessor: ({ accountType }: Account) => (
        <div>{AccountType[accountType]}</div>
      )
    },
    {
      key: 'roles',
      Header: 'Roles (currently all accounts share the same roles)',
      accessor: ({ id, accountType }: Account) => {
        return (
          <RoleActionWrapper
            accountId={id}
            accountType={accountType}
            fetchUser={fetchUser}
            roles={roles}
            user={user}
            page={params.pageNumber}
          />
        );
      }
    },
    {
      key: 'remove',
      Header: '',
      accessor: (acct: Account) => {
        return (
          <Button
            disabled={deleteLoading}
            variant={'error'}
            onClick={async e => {
              e.stopPropagation();
              setRemoveUserOpen(true);
              setAccount(acct);
            }}>
            Remove
          </Button>
        );
      }
    }
  ];

  return (
    <div>
      <Card style={{ marginBottom: '50px' }}>
        <WWTable
          columns={COLUMNS}
          data={response?.items || []}
          itemName="Accounts"
          totalRecords={response?.count || 0}
          loading={isFetching}
          paginated
          page={params.pageNumber}
          pageSize={params.pageSize}
          onPageChanged={setPageNumber}
          onPageSizeChanged={setPageSize}
          searchable
          onSearchChanged={setSearchTermWrapper}
        />
      </Card>
      {account && user && (
        <RemoveAccountModal
          accountId={account.id}
          accountName={account.name}
          userToRemove={user}
          isLoading={isFetching}
          deleteLoading={deleteLoading}
          setDeleteLoading={setDeleteLoading}
          isRemovingUserOpen={isRemovingUserOpen}
          setRemoveUserOpen={setRemoveUserOpen}
        />
      )}
    </div>
  );
};

const AccountNameWrapper = styled.div`
  max-width: 200px;
  word-break: break-word;
`;

export default AccountsTable;
