import * as React from 'react';
import { Icon, Segment } from 'semantic-ui-react';

// Models
import { Account, Role } from '@120wateraudit/envirio-components/dist/models';
import { User } from 'src/types/AccountManagementTypes';
import { Auth0Role } from 'src/types/Auth0Role';

// Components
import { Badge, Button, colors } from '@120wateraudit/envirio-components';
import UserForm from 'src/components/Forms/User';
import styled from 'src/theme';
import {
  GenericPlaceholder,
  PlaceholderTable,
  UserFormPlaceholder
} from './placeholders';
import AccountsTable from './AccountsTable';
import AddAccountModal from './AddAccountModal';

interface Props {
  addingToAccount: boolean;
  applicationRoleOptions: Auth0Role[];
  applicationRoles: { [key in Auth0Role['_id']]: boolean };
  defaultAccounts: { [key in string]: Account | null };
  defaultAccountSetter: (type: number, account: Account | null) => void;
  editingRoles: boolean;
  isFetching: boolean;
  isSaving: boolean;
  roleOptions: any;
  rolesAccount?: Account;
  roles: Role[];
  user: User;

  // Actions
  onCheckboxChanged: any;
  onUpdateClicked: () => void;
  toggleAddingToAccount: () => void;
  toggleEditingRoles: () => void;
  fetchUser: () => void;
}
const UserDetails = ({
  addingToAccount,
  applicationRoleOptions,
  applicationRoles,
  defaultAccounts,
  defaultAccountSetter,
  isFetching,
  isSaving,
  onCheckboxChanged,
  roles,
  onUpdateClicked,
  toggleAddingToAccount,
  user,
  fetchUser
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginTop: '20px'
      }}>
      <Segment attached clearing>
        <div style={{ float: 'left' }}>
          <div>
            <h4>
              {isFetching ? (
                <GenericPlaceholder />
              ) : (
                `${user.firstName || ''} ${user.lastName || ''}`
              )}
            </h4>
            <TopSectionHeader>
              <UserInfoHeaderTitle>User Id:&nbsp;</UserInfoHeaderTitle>
              <UserInfoHeader>
                {isFetching ? (
                  <GenericPlaceholder
                    style={{ width: '200px', marginLeft: '5px' }}
                  />
                ) : (
                  ` ${user.id || ''}`
                )}
              </UserInfoHeader>
            </TopSectionHeader>
          </div>
        </div>
        {!isFetching &&
          user.roles &&
          user.roles.some(r => r.name === '120Employee') && (
            <div style={{ float: 'right' }}>
              <Badge variant="primary">120 Employee</Badge>
            </div>
          )}
        {isFetching && (
          <GenericPlaceholder
            style={{ float: 'right', width: '200px', marginTop: '0px' }}
          />
        )}
      </Segment>
      <Segment attached clearing>
        <div style={{ paddingBottom: '20px' }}>
          {isFetching ? (
            <UserFormPlaceholder />
          ) : (
            <UserForm
              user={user}
              applicationRoleOptions={applicationRoleOptions}
              applicationRoles={applicationRoles}
              defaultAccounts={defaultAccounts}
              defaultAccountSetter={defaultAccountSetter}
              isNew={false}
              onCheckboxChanged={onCheckboxChanged}
              showRoles={false}
            />
          )}
        </div>
      </Segment>
      <Segment attached clearing>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Button
            disabled={isSaving || isFetching}
            onClick={onUpdateClicked}
            variant="primary">
            {isSaving ? 'Updating...' : 'Update'}
          </Button>
        </div>
      </Segment>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Button
          disabled={isFetching}
          onClick={toggleAddingToAccount}
          variant="primary">
          <Icon className="plus" style={{ marginRight: '1rem' }} />
          Add Existing Account
        </Button>
      </div>
      <h5 style={{ float: 'left' }}>Accounts</h5>
      {user && user.id && (
        <>
          {isFetching ? (
            <PlaceholderTable />
          ) : (
            <AccountsTable user={user} roles={roles} fetchUser={fetchUser} />
          )}
          {
            <AddAccountModal
              user={user}
              toggleAddingToAccount={toggleAddingToAccount}
              addingToAccount={addingToAccount}
            />
          }
        </>
      )}
    </div>
  );
};

const UserInfoHeaderTitle = styled.h6`
  color: ${colors.black};
  display: inline;
  margin-bottom: 0;
  margin-top: 0;
`;

const UserInfoHeader = styled.h6`
  color: ${colors.primary};
  display: inline;
  margin-bottom: 0;
  margin-top: 0;
`;

const TopSectionHeader = styled.div`
  display: flex;
  align-items: center;
`;

export default UserDetails;
