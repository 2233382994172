import * as React from 'react';

// Models
import { User } from 'src/types/AccountManagementTypes';

// Components
import { Card, Table as WWTable } from '@120wateraudit/waterworks';
import LinkCell from 'src/components/Cells/LinkCell';
import styled from 'src/theme';

// Custom Hooks
import { useTableState } from 'src/hooks/useTableState';
import { useGetUsersQuery } from 'src/services';

const COLUMNS = [
  {
    key: 'name',
    Header: 'User Name',
    sortable: false,
    accessor: ({ id, firstName, lastName }: User) => {
      return <LinkCell text={`${firstName} ${lastName}`} to={`/users/${id}`} />;
    }
  },
  {
    key: 'roles',
    Header: 'Roles',
    sortable: false,
    accessor: ({ roles }: User) => {
      return (
        <RolesColumnWrapper>
          {roles ? roles.map(r => r.name).join(', ') : '--'}
        </RolesColumnWrapper>
      );
    }
  },
  {
    key: 'email',
    Header: 'Email',
    sortable: false,
    accessor: 'email'
  }
];

const Users = () => {
  const {
    params,
    setSearchTermWrapper,
    setPageSize,
    setPageNumber
  } = useTableState({
    searchQueryType: 'search'
  });
  const { data: response, isFetching } = useGetUsersQuery({
    paramsData: params
  });

  return (
    <Card style={{ marginTop: '20px' }}>
      <WWTable
        columns={COLUMNS}
        data={response?.items || []}
        itemName="Users"
        totalRecords={response?.count || 0}
        loading={isFetching}
        paginated
        page={params.pageNumber}
        pageSize={params.pageSize}
        onPageChanged={setPageNumber}
        onPageSizeChanged={setPageSize}
        searchable
        onSearchChanged={setSearchTermWrapper}
      />
    </Card>
  );
};

const RolesColumnWrapper = styled.div`
  max-width: 600px;
  word-break: break-word;
`;

export default Users;
