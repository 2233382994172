import * as React from 'react'
import { connect } from 'react-redux'

// Components
import InviteAccountUser from 'src/components/AccountUsers/invite'

// Models
import {
  School,
  SchoolDistrict,
  User
} from '@120wateraudit/envirio-components/dist/models'

// Utils
import { pushRoute } from 'src/utils/Navigation'

// Actions
import { fetchRolesRequest as fetchApplicationRolesRequest } from 'src/actions/applicationAccessRoles'
import { fetchRolesRequest } from 'src/actions/roles'
import { fetchSchoolDistrictsRequest } from 'src/actions/schoolDistricts'
import { fetchSchoolsRequest } from 'src/actions/schools'
import { inviteUserRequest } from 'src/actions/userInvite'
import { ApplicationState } from 'src/reducers'
import {
  getSchoolDistricts,
  getSchoolDistrictsAsSelectList
} from 'src/selectors/schoolDistricts'
import { getSchools, getSchoolsAsSelectList } from 'src/selectors/schools'
import { getApplicationRoles } from '../../selectors/applicationAccessRoles'
import { getRoles, getRolesAsSelectList } from '../../selectors/roles'
import { Auth0Role } from '../../types/Auth0Role'

interface State {
  user: Partial<User> & {
    applicationRoles: { [key in Auth0Role['_id']]: boolean }
    schoolDistricts: SchoolDistrict[]
    schools: School[]
    roleOptionToggle: RoleOptionToggle
    useSSO: boolean
  }
}

export interface RoleOptionToggle {
  isDistrictUser: boolean
  isFacilityUser: boolean
  isAdminUser: boolean
}
class InviteAccountUserContainer extends React.Component<any, State> {
  [x: string]: any
  constructor(props: any) {
    super(props)
    this.state = {
      user: {
        email: '',
        roles: [],
        schoolDistricts: [],
        schools: [],
        applicationRoles: {},
        useSSO: false,
        roleOptionToggle: {
          isDistrictUser: false,
          isFacilityUser: false,
          isAdminUser: true
        }
      }
    }
  }

  componentWillMount() {
    this.getAllRoles()
    this.getAllSchoolDistricts()
    this.getAllSchools()
  }

  getAllRoles = () => {
    this.props.fetchApplicationRolesRequest()
    this.props.fetchRolesRequest()
  }

  getAllSchoolDistricts = () => {
    const { accountId } = this.props.match.params
    this.props.fetchSchoolDistrictsRequest({ accountId })
  }

  getAllSchools = () => {
    const { accountId } = this.props.match.params
    this.props.fetchSchoolsRequest({ accountId })
  }

  onCancelClicked = () => {
    const { accountId } = this.props.match.params
    pushRoute(`/accounts/${accountId}`)
  }

  onInputChanged = (e: any) => {
    if (this.state.user.hasOwnProperty(e.target.name) && e.target.name) {
      this.setState({
        user: {
          ...this.state.user,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  onSelectListChanged = (data: any, modelKey: string) => {
    if (this.state.user.hasOwnProperty(modelKey)) {
      this.setState({
        user: {
          ...this.state.user,
          [modelKey]: data.value
        }
      })
    }
  }

  onRoleTypeChanged = ({ value }: { value: string[] }) => {
    const roles = this.props.roles.filter(r => value.indexOf(r.id) > -1)
    const roleOptionToggle: RoleOptionToggle = {
      isDistrictUser: false,
      isFacilityUser: false,
      isAdminUser: true
    }

    roles.forEach(role => {
      if (role.name === 'district-users') {
        roleOptionToggle.isDistrictUser = true
        roleOptionToggle.isAdminUser = false
      }

      if (role.name === 'facility-users') {
        roleOptionToggle.isFacilityUser = true
        roleOptionToggle.isAdminUser = false
      }
    })

    this.setState({
      user: {
        ...this.state.user,
        roles,
        roleOptionToggle
      }
    })
  }

  onSchoolDistrictsChanged = ({ value }: { value: string[] }) => {
    const schoolDistricts = this.props.schoolDistricts.filter(
      r => value.indexOf(r.id) > -1
    )

    this.setState({
      user: {
        ...this.state.user,
        schoolDistricts
      }
    })
  }

  onSchoolsChanged = ({ value }: { value: string[] }) => {
    const schools = this.props.schools.filter(
      school => value.indexOf(school.id) > -1
    )
    this.setState({
      user: {
        ...this.state.user,
        schools
      }
    })
  }
  onInviteClicked = () => {
    const { accountId } = this.props.match.params
    const { user } = this.state
    const applicationRoles: string[] = []

    // Get the roleIDs for application roles from our applicationRoles object
    // e.g:
    // {
    //   "0e0e151f-4a07-4dee-b347-cf6839b355e2": true,
    // 	 "1e03151f-4a07-4dae-b347-sd6839b355e2": false
    // }
    Object.keys(user.applicationRoles).forEach(key => {
      // Filter out application roles that are set to false
      if (user.applicationRoles[key]) {
        applicationRoles.push(key)
      }
    })

    // Dispatch invite action
    this.props.inviteUser({
      accountId,
      email: user.email && user.email.trim(),
      roles: user.roles ? user.roles.map(r => r.id).join(',') : [],
      schoolDistricts: user.schoolDistricts
        ? user.schoolDistricts.map(sd => sd.id).join(',')
        : [],
      schools: user.schools
        ? user.schools.map(school => school.id).join(',')
        : [],
      useSSO: user.useSSO,
      applicationRoles
    })
  }

  onCheckboxChanged = (auth0RoleId: any, { checked }: { checked: boolean }) => {
    this.setState({
      user: {
        ...this.state.user,
        applicationRoles: {
          ...this.state.user.applicationRoles,
          [auth0RoleId]: checked
        }
      }
    })
  }

  ssoCheckboxChanged = () => {
    const { user } = this.state
    this.setState({ user: { ...user, useSSO: !user.useSSO } })
  }

  render() {
    return (
      <>
        <InviteAccountUser
          user={this.state.user}
          onInputChanged={this.onInputChanged}
          onCheckboxChanged={this.onCheckboxChanged}
          ssoCheckboxChanged={this.ssoCheckboxChanged}
          onSelectListChanged={this.onSelectListChanged}
          isSaving={this.props.isSaving}
          error={this.props.error}
          onClickInvite={this.onInviteClicked}
          onCancelClicked={this.onCancelClicked}
          roleOptions={this.props.roleOptions}
          schoolDistrictOptions={this.props.schoolDistrictOptions}
          schoolOptions={this.props.schoolOptions}
          onRoleTypeChanged={this.onRoleTypeChanged}
          onSchoolDistrictsChanged={this.onSchoolDistrictsChanged}
          onSchoolsChanged={this.onSchoolsChanged}
          applicationRoles={this.props.applicationRoles}
        />
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  isSaving: state.userInvite.isSaving,
  roles: getRoles(state),
  roleOptions: getRolesAsSelectList(state),
  schoolDistricts: getSchoolDistricts(state),
  schoolDistrictOptions: getSchoolDistrictsAsSelectList(state),
  schools: getSchools(state),
  schoolOptions: getSchoolsAsSelectList(state),
  applicationRoles: getApplicationRoles(state),
  error: state.userInvite.error
})

const mapDispatchToProps = {
  inviteUser: inviteUserRequest,
  fetchRolesRequest,
  fetchApplicationRolesRequest,
  fetchSchoolDistrictsRequest,
  fetchSchoolsRequest
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteAccountUserContainer)
