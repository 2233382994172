import { omit } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

// Containers
import FormContainer from 'src/containers/FormContainer';

// Components
import Settings from 'src/components/Settings/accountSettings';

// Models
import { Setting } from '@120wateraudit/envirio-components/dist/models';

// Actions
import SettingDefinitionsActions from 'src/actions/settingDefinitions';
import SettingsActions from 'src/actions/settings';
import { ApplicationState } from 'src/reducers';
import {
  getSettingDefinitions,
  getSettingDefinitionsAsSelectList
} from 'src/selectors/settingDefinitions';
import { getSettings } from 'src/selectors/settings';
import { APIProvider } from 'src/utils/API';
import { timezoneOptions as timezoneDropDown } from 'src/utils/SelectList';
import { DropdownItemProps } from 'semantic-ui-react';
import { toast, ToastOptions } from 'react-toastify';

interface Props {
  settings: Setting;
  isFetching: boolean;
  getSettingDefinitions: any;
  getSettingDefinitionsAsSelectList: any;
  // Actions
  fetchSettings: () => any;
  fetchSettingDefinitions: () => any;
  createSettings: (params: {
    accountId: number;
    settingDefinitionId: number;
    settings: any;
  }) => any;
  updateSettings: (params: {
    accountId: number;
    settingId: number;
    settings: any;
  }) => any;
  deleteSettings: (params: { accountId: number; settingId: number }) => any;
}

interface State {
  isEditing: boolean;
  isCreating: boolean;
  isModalOpen: boolean;
  model: any;
  newSetting?: any;
  targetSetting?: Setting;
  templateOptions?: DropdownItemProps[];
  timezoneOptions: DropdownItemProps[];
  sendGridHtml?: string;
  isLoading: boolean;
  selectedDefinitionName: string;
  selectedDefinitionPlaceholder: string;
}

class SettingsContainer extends FormContainer<Props, State> {
  settingsGrid: any;

  constructor(props: any) {
    super(props);

    this.state = {
      isEditing: false,
      isCreating: false,
      isModalOpen: false,
      sendGridTemplates: [],
      timezoneOptions: timezoneDropDown,
      isLoading: false
    };
  }

  componentWillMount() {
    this.fetchSettingDefinitions();
    this.fetchSettings();
    this.fetchSendGridTemplates();
  }

  initSettingsGrid = (gridEl: any) => {
    this.settingsGrid = gridEl;
  };

  reloadGrid = () => {
    this.settingsGrid.fetchData();
  };

  fetchSettings = () => {
    this.props.fetchSettings({
      accountId: this.props.accountId
    });
  };

  fetchSendGridTemplates = () => {
    const defaultToastOpts: ToastOptions = {
      position: 'top-center',
      autoClose: 2500,
      hideProgressBar: true
    };

    APIProvider.fetchSendGridTemplates()
      .then(results => {
        const sendGridTemplateOptions = results.map(
          (template: any, index: number): DropdownItemProps => ({
            key: template.id + index,
            text: template.name + ` - (${template.id})`,
            value: template.id
          })
        );

        this.setState({
          templateOptions: sendGridTemplateOptions
        });
      })
      .catch(_error => {
        toast('Failed to retrieve email templates,', {
          ...defaultToastOpts,
          type: 'error'
        });
      });
  };

  fetchSettingDefinitions = () => {
    this.props.fetchSettingDefinitions({
      accountId: this.props.accountId,
      accountType: this.props.account.accountType
    });
  };

  toggleCreating = () => {
    this.setState({
      isCreating: !this.state.isCreating
    });
  };

  toggleEditing = () => {
    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    });
  };

  onAddClicked = () => {
    const { accountId } = this.props;

    this.props.createSettings({
      accountId,
      settingDefinitionId: this.state.model.settingDefinitionId,
      settings: {
        ...this.state.model,
        accountId
      },
      callback: () => {
        this.fetchSettingDefinitions();
        this.reloadGrid();
      }
    });

    this.setState({
      newSetting: undefined
    });

    this.toggleCreating();
  };

  onUpdateClicked = () => {
    this.props.updateSettings({
      accountId: this.props.accountId,
      settingId: this.state.targetSetting.id,
      settings: omit(this.state.targetSetting, [
        'account',
        'settingDefinition'
      ]),
      callback: () => {
        this.reloadGrid();
      }
    });

    this.setState({
      newSetting: undefined
    });

    this.toggleEditing();
  };

  onDeleteClicked = () => {
    this.props.deleteSettings({
      accountId: this.props.accountId,
      settingId: this.state.targetSetting.id,
      callback: () => {
        this.fetchSettingDefinitions();
        this.reloadGrid();
        this.forceUpdate();
        this.setState({
          isCreating: true
        });
      }
    });

    this.toggleModal();
  };

  onSelectListChanged = (_e, selectedDefinition) => {
    const getDefinitionNameFromKey = selectedDefinition.options.filter(
      option => {
        return option.value === selectedDefinition.value;
      }
    );

    this.setState({
      isCreating: true,
      model: { settingDefinitionId: selectedDefinition.value },
      selectedDefinitionName: getDefinitionNameFromKey[0].key,
      selectedDefinitionPlaceholder: getDefinitionNameFromKey[0].placeholder
    });
  };

  getSendGridTemplateHtml = (id: string) => {
    this.setState({
      isLoading: true
    });
    APIProvider.fetchSendGridTemplate(id).then(result => {
      this.setState({
        sendGridHtml: result.versions[0].html_content,
        isLoading: false
      });
    });
  };

  onDropdownChanged = (_e, selectedDefinition) => {
    this.setState({
      model: {
        ...this.state.model,
        value: selectedDefinition.value
      },
      targetSetting: {
        ...this.state.targetSetting,
        value: selectedDefinition.value
      }
    });
  };

  onLeadBanDateChanged = (date: Date) => {
    this.setState({
      model: {
        ...this.state.model,
        value: date.toLocaleDateString()
      },
      targetSetting: {
        ...this.state.targetSetting,
        value: date.toLocaleDateString()
      }
    });
  };

  onTemplateChanged = (_e, selectedDefinition) => {
    this.setState({
      model: {
        ...this.state.model,
        value: selectedDefinition.value
      },
      targetSetting: {
        ...this.state.targetSetting,
        value: selectedDefinition.value
      }
    });
    this.getSendGridTemplateHtml(selectedDefinition.value);
  };

  onEditClicked = (targetSetting: Setting) => {
    this.setState({
      isEditing: !this.state.isEditing,
      targetSetting
    });
  };

  onSettingValueChanged = (e: any) => {
    this.setState({
      targetSetting: {
        ...this.state.targetSetting,
        value: e.target.value
      }
    });
  };

  render() {
    const {
      model,
      isEditing,
      isCreating,
      isModalOpen,
      newSetting,
      targetSetting,
      sendGridHtml,
      templateOptions,
      isLoading,
      timezoneOptions,
      selectedDefinitionName,
      selectedDefinitionPlaceholder
    } = this.state;
    const {
      account,
      accountId,
      settings,
      accountType,
      isSaving,
      settingDefinitionOptions
    } = this.props;

    return (
      <Settings
        account={account}
        accountId={accountId}
        accountType={accountType}
        gridRef={this.initSettingsGrid}
        settings={settings}
        isCreating={isCreating}
        isEditing={isEditing}
        isModalOpen={isModalOpen}
        isSaving={isSaving}
        newSetting={newSetting}
        onClickAdd={this.onAddClicked}
        onClickDelete={this.onDeleteClicked}
        onClickEdit={this.onEditClicked}
        onClickUpdate={this.onUpdateClicked}
        onInputChanged={this.onInputChanged}
        onSelectListChanged={this.onSelectListChanged}
        onSettingValueChanged={this.onSettingValueChanged}
        selectedSetting={model ? model.settingDefinitionId : undefined}
        settingDefinitionOptions={settingDefinitionOptions}
        targetSetting={targetSetting}
        toggleCreating={this.toggleCreating}
        toggleEditing={this.toggleEditing}
        toggleModal={this.toggleModal}
        templateOptions={templateOptions}
        timezoneOptions={timezoneOptions}
        onDropdownChanged={this.onDropdownChanged}
        onTemplateChanged={this.onTemplateChanged}
        onLeadBanDateChanged={this.onLeadBanDateChanged}
        sendGridHtml={sendGridHtml}
        isLoading={isLoading}
        selectedDefinitionName={selectedDefinitionName}
        selectedDefinitionPlaceholder={selectedDefinitionPlaceholder}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  settings: getSettings(state),
  isFetching: state.settings.isFetching,
  settingDefinitionOptions: getSettingDefinitionsAsSelectList(state),
  settingDefinitions: getSettingDefinitions(state)
});

const mapDispatchToProps = {
  fetchSettingDefinitions:
    SettingDefinitionsActions.collectionActions.fetchRequest,
  fetchSettings: SettingsActions.collectionActions.fetchRequest,
  createSettings: SettingsActions.createActions.createRequest,
  updateSettings: SettingsActions.updateActions.updateRequest,
  deleteSettings: SettingsActions.deleteActions.deleteRequest
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
