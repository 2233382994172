import * as React from 'react';
import { Container } from 'semantic-ui-react';
import AccountUsers from 'src/components/AccountUsers';

interface Props {
  accountId: number;
  accountName: string;
}

const AccountUsersContainer = ({ accountId, accountName }: Props) => {
  return (
    <Container>
      <AccountUsers accountId={accountId} accountName={accountName} />
    </Container>
  );
};

export default AccountUsersContainer;
